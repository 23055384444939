import Vue from 'vue';
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router';

import {articleRoutes} from '@/articles/router';
import {bookingRoutes} from '@/bookings/router';
import {feedbackRoutes} from '@/feedback/router';
import {menuRoutes} from '@/menu/router';

import {store} from './store';
import Cgu from './views/Cgu.vue';
import Home from './views/Home.vue';
import MentionsLegales from './views/MentionsLegales.vue';
import PageNotFound from './views/PageNotFound.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/non-trouve',
    name: 'notFound',
    component: PageNotFound,
    meta: {title: 'Page introuvable'},
  },
  {
    path: '/:slug',
    name: 'home',
    component: Home,
    meta: {title: 'Accueil'},
  },
  {
    path: '/:slug/MentionsLegales',
    name: 'legalNotice',
    component: MentionsLegales,
    meta: {title: 'Mentions Légales'},
  },
  {
    path: '/:slug/cgu',
    name: 'termsOfService',
    component: Cgu,
    meta: {title: 'CGU'},
  },
  ...menuRoutes,
  ...articleRoutes,
  ...bookingRoutes,
  ...feedbackRoutes,
  {
    path: '/*',
    redirect: '/non-trouve',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext): void => {
  if (to.params.slug && !router.app.$store.getters['site/customerId']) {
    store.dispatch('site/getSite', to.params.slug);
  }
  next();
});
export default router;
