import {Booking, BookingConfiguration, BookingState, CountBooking} from '../interfaces';

export const mutations = {
  SET_CURRENT_BOOKING(state: BookingState, booking: Booking): void {
    state.currentBooking = booking;
  },
  SET_CONFIG(state: BookingState, bookingConfig: BookingConfiguration): void {
    state.bookingConfig = bookingConfig;
  },
  SET_COUNT_BOOKINGS(state: BookingState, countBookings: CountBooking[]): void {
    state.countBookings = countBookings;
  },
  SET_ERROR(state: BookingState, error: boolean): void {
    state.error = error;
  },
  SET_ERROR_MESSAGE(state: BookingState, errorMessage: string): void {
    state.errorMessage = errorMessage;
  },
  SET_LOADING(state: BookingState, loading: boolean): void {
    state.loading = loading;
  },
  SET_RESPONSE(state: BookingState, response: boolean): void {
    state.response = response;
  },
};
