import {Booking, BookingConfiguration, BookingState, CountBooking} from '../interfaces';

export const getters = {
  bookingConfig: (state: BookingState): BookingConfiguration | null => state.bookingConfig,
  error: (state: BookingState): boolean => state.error,
  loading: (state: BookingState): boolean => state.loading,
  response: (state: BookingState): boolean => state.response,
  errorMessage: (state: BookingState): string => state.errorMessage,
  countBookings: (state: BookingState): CountBooking[] => state.countBookings,
  currentBooking: (state: BookingState): Booking | null => state.currentBooking,
};
