export enum PRODUCT_TYPE {
  ENT = 'ENT',
  PLA = 'PLA',
  DES = 'DES',
  BOI = 'BOI',
  FOR = 'FOR',
  LAI = 'LAI',
  FRO = 'FRO',
  SNA = 'SNA',
  LOC = 'LOC',
}

export enum SERVICE_TYPE {
  PD = 'PD',
  CO = 'CO',
  DJ = 'DJ',
  GO = 'GO',
  SO = 'SO',
}
