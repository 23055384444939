import {RouteConfig} from 'vue-router';

import News from './components/News.vue';

export const articleRoutes: Array<RouteConfig> = [
  {
    path: '/:slug/articles',
    name: 'articles',
    component: News,
    meta: {title: 'Articles'},
  },
];
