import {AxiosResponse} from 'axios';

import axios from '@/core/tools/axios';

import {AffluenceParams} from '../interfaces';

export default class SiteService {
  static getSite(slug: string): Promise<AxiosResponse> {
    return axios.get(`/sites/by-slug/${slug}`);
  }

  static getSiteByCustomerId(customerId: string): Promise<AxiosResponse> {
    return axios.get(`/sites/${customerId}`);
  }

  static getAffluence(affluenceParams: AffluenceParams): Promise<AxiosResponse> {
    return axios.get(`/affluence`, {
      params: {...affluenceParams},
    });
  }
}
