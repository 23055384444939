import {AffluenceResponse, AffluenceZone, Links, SiteState, Theme} from '../interfaces';

export const getters = {
  affluenceResponse: (state: SiteState): AffluenceResponse[] => state.affluenceResponse,
  affluenceTZ: (state: SiteState): number => state.affluenceTZ,
  affluenceZones: (state: SiteState): AffluenceZone[] => state.affluenceZones,
  customerId: (state: SiteState): string => state.customerId,
  error: (state: SiteState): boolean => state.error,
  enableAffluence: (state: SiteState): boolean => state.enableAffluence,
  enableContact: (state: SiteState): boolean => state.enableContact,
  enableFeedback: (state: SiteState): boolean => state.enableFeedback,
  favicon: (state: SiteState): string => state.ui.favicon,
  isBookingActivated: (state: SiteState): boolean => state.isBookingActivated,
  isDemo: (state: SiteState): boolean => state.isDemo,
  links: (state: SiteState): Links | null => state.links,
  loading: (state: SiteState): boolean => state.loading,
  logo: (state: SiteState): string => state.ui.logo,
  logo2: (state: SiteState): string | null => state.ui.logo2,
  motd: (state: SiteState): string | null => state.motd,
  name: (state: SiteState): string => state.name,
  slug: (state: SiteState): string => state.slug,
  theme: (state: SiteState): Theme => state.ui.theme,
};
