import {ALLERGENES, NUTRISCORE, ORIGINES, PRODUCT_TYPES} from '../constants/collections';
import {PRODUCT_TYPE} from '../constants/enums';
import {Day, Menu, Picto, Product, Service} from '../interfaces';

export function productsByTypeProduct(
  products: Product[],
  typeProduct: PRODUCT_TYPE | string
): Product[] {
  return products.filter((product) => product.type === typeProduct);
}

export function filterAllergenes(allergenes: string[]): Picto[] {
  return ALLERGENES.filter((localAllergene) =>
    allergenes.some((allergene: string) => allergene === localAllergene.id)
  );
}
export function filterOrigines(origines: string[]): Picto[] {
  return ORIGINES.filter((localOrigine) =>
    origines.some((origine: string) => origine === localOrigine.id)
  );
}

export function filterType(product: Product): string {
  return PRODUCT_TYPES.filter((productType) => productType.id === product.type)[0].name || '';
}

export function filterTypesProduct(products: Product[]): Picto[] {
  return PRODUCT_TYPES.filter((type) => products.some((product) => product.type === type.id));
}

export function hasMenus(menus: Menu[], day: Day | null, service: Service): boolean {
  return menus.some(
    (menuFiltred) =>
      day && menuFiltred.date === day.date && menuFiltred.serviceCode === service.code
  );
}

export function getMenuId(menus: Menu[], day: Day, service: Service): string {
  const foundMenu = menus.find(
    (menuFiltred) => menuFiltred.date === day.date && menuFiltred.serviceCode === service.code
  );
  return foundMenu
    ? foundMenu._id
    : menus.filter((menuFiltred) => menuFiltred.date === day.date)[0]._id;
}

export function filterNutriscore(nustriscoreProduct: number, isVertical: boolean): string {
  return isVertical
    ? NUTRISCORE.filter((nutriscore) => nutriscore.id === nustriscoreProduct)[0].fileNameVertical ||
        ''
    : NUTRISCORE.filter((nutriscore) => nutriscore.id === nustriscoreProduct)[0].fileName || '';
}
