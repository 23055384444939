/* eslint-disable @typescript-eslint/ban-types */
import {Booking} from '../interfaces';
import BookingService from '../services/BookingService';

export const actions = {
  sendBooking: async ({commit}: {commit: Function}, booking: Booking): Promise<void> => {
    commit('SET_ERROR', false);
    commit('SET_LOADING', false);
    BookingService.createBooking(booking)
      .then((response) => {
        if (response.data.code === 201) commit('SET_RESPONSE', true);
        else {
          commit('SET_ERROR', true);
          commit('SET_ERROR_MESSAGE', response.data.message);
        }
      })
      .catch(() => {
        commit('SET_ERROR', true);
      })
      .finally(() => commit('SET_LOADING', false));
  },
  countBookings: async (
    {commit}: {commit: Function},
    params: {customerId: string; date: string}
  ): Promise<void> => {
    commit('SET_LOADING', false);
    BookingService.countBookings(params.customerId, params.date)
      .then((response) => {
        commit('SET_COUNT_BOOKINGS', response.data);
      })
      .catch(() => {
        commit('SET_ERROR', true);
      })
      .finally(() => commit('SET_LOADING', false));
  },
  getBooking: async ({commit}: {commit: Function}, id: string): Promise<void> => {
    commit('SET_ERROR', false);
    commit('SET_LOADING', false);
    BookingService.getBooking(id)
      .then((response) => {
        commit('SET_CURRENT_BOOKING', response.data);
      })
      .catch(() => {
        commit('SET_ERROR', true);
      })
      .finally(() => commit('SET_LOADING', false));
  },
  cancelBooking: async ({commit}: {commit: Function}, id: string): Promise<void> => {
    commit('SET_ERROR', false);
    commit('SET_LOADING', false);
    BookingService.cancelBooking(id)
      .then((response) => {
        if (response.status === 200) commit('SET_RESPONSE', true);
        else {
          commit('SET_ERROR', true);
          commit('SET_ERROR_MESSAGE', response.statusText);
        }
      })
      .catch(() => {
        commit('SET_ERROR', true);
      })
      .finally(() => commit('SET_LOADING', false));
  },
  reset: ({commit}: {commit: Function}): void => {
    commit('SET_ERROR', false);
    commit('SET_RESPONSE', false);
    commit('SET_LOADING', false);
    commit('SET_ERROR_MESSAGE', '');
    commit('SET_CURRENT_BOOKING', null);
  },
};
