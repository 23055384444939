











































































































import {Component, Prop} from 'vue-property-decorator';

import {Product} from '../interfaces';
import BaseProduct from './BaseProduct.vue';

@Component
export default class ModalProduct extends BaseProduct {
  @Prop() readonly product!: Product;
}
