






































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class NoData extends Vue {
  @Prop(Boolean) isNotFoundPage!: boolean;

  @Prop(Boolean) hasItems!: boolean;

  @Prop(String) typeData!: string;
}
