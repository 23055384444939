

























import {Component, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import AppBar from '@/core/components/AppBar.vue';
import Footer from '@/core/components/Footer.vue';
import {Theme} from '@/site/interfaces';

@Component({
  components: {
    AppBar,
    Footer,
  },
})
export default class App extends Vue {
  @Getter('loading', {namespace: 'site'}) loading!: boolean;

  @Getter('error', {namespace: 'site'}) error!: boolean;

  @Getter('theme', {namespace: 'site'}) theme!: Theme;

  @Getter('favicon', {namespace: 'site'}) favicon!: string;

  @Getter('name', {namespace: 'site'}) siteName!: string;

  @Watch('$store.state.site.name')
  private updateTitle() {
    if (this.siteName) {
      document.title = this.siteName;
    }
  }

  @Watch('$store.state.site.ui')
  private updateUi() {
    if (this.theme) {
      Object.keys(this.theme).forEach((i) => {
        this.$vuetify.theme.themes.light[i] = this.theme[i];
      });
    }
    // if (this.favicon) {
    //   const link = document.querySelector("link[rel~='icon']");
    //   if (link) link.setAttribute('href', this.favicon);
    // }
  }

  @Watch('$store.state.site.error')
  private onError() {
    if (this.error && this.$route.name !== 'notFound') {
      document.title = 'Page introuvable';
      this.$router.replace('/non-trouve');
    }
  }
}
