

















































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import {Links} from '@/site/interfaces';

import AffluenceModal from './AffluenceModal.vue';

@Component({
  components: {AffluenceModal},
})
export default class AppBar extends Vue {
  @Getter('isDemo', {namespace: 'site'}) isDemo!: boolean;

  @Getter('logo', {namespace: 'site'}) logo!: string;

  @Getter('logo2', {namespace: 'site'}) logo2!: string | null;

  @Getter('links', {namespace: 'site'}) links!: Links;

  @Getter('hasArticles', {namespace: 'article'}) hasArticles!: boolean;

  @Getter('isBookingActivated', {namespace: 'site'}) isBookingActivated!: boolean;

  @Getter('enableContact', {namespace: 'site'}) enableContact!: boolean;

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  @Getter('enableAffluence', {namespace: 'site'}) enableAffluence!: boolean;

  affluenceDialog = false;

  services: {
    title: string;
    link?: string | null;
    name?: string;
    show: boolean;
  }[] = [];

  onlineServices: {
    title: string;
    link?: string | null;
    name?: string;
    show: boolean;
  }[] = [];

  @Watch('$store.state.site.links')
  setLinks(): void {
    if (this.links) {
      this.onlineServices = [
        {
          title: 'Compte',
          link: this.links.badgeUrl || null,
          show: !!this.links.badgeUrl,
        },
        {
          title: 'Room Service',
          link: this.links.roomServiceUrl || null,
          show: !!this.links.roomServiceUrl,
        },
        {
          title: 'À emporter',
          link: this.links.clickAndCollectUrl || null,
          show: !!this.links.clickAndCollectUrl,
        },
      ].filter((service) => service.show);
    }
  }

  @Watch('$store.state.article.articles')
  showLocalService(): void {
    this.setServices();
  }

  mounted(): void {
    this.setServices();
    this.setLinks();
  }

  setServices(): void {
    if (this.hasArticles) {
      this.services = [
        {
          title: 'Menus',
          name: 'menu',
          show: true,
        },
        {
          title: 'Actualités',
          name: 'articles',
          show: this.hasArticles,
        },
        {
          title: 'Réservation',
          name: 'booking',
          show: this.isBookingActivated,
        },
        {
          title: 'Contact',
          name: 'contact',
          show: this.enableContact,
        },
      ].filter((service) => service.show);
    } else {
      this.services = [
        {
          title: 'Menus',
          name: 'menu',
          show: true,
        },
        {
          title: 'Réservation',
          name: 'booking',
          show: this.isBookingActivated,
        },
        {
          title: 'Contact',
          name: 'contact',
          show: this.enableContact,
        },
      ].filter((service) => service.show);
    }
  }

  selectService(service: {name: string; params: {id: string}}): void {
    this.$router.push(service);
  }
}
