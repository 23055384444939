import {Theme} from '../interfaces';

export const THEME: Theme = {
  primary: '#9a1429',
  secondary: '#333333',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  anchor: '#9a1429',
  affluenceDirect: '#E74545',
  affluenceAvg: '#669DF6',
};
