import {THEME} from '../constants';
import {SiteState} from '../interfaces';

export const state: SiteState = {
  error: false,
  links: null,
  loading: false,
  name: '',
  customerId: '',
  ui: {
    logo: '',
    logo2: null,
    favicon: '',
    theme: THEME,
  },
  isBookingActivated: false,
  slug: '',
  isDemo: false,
  motd: null,
  enableContact: false,
  enableFeedback: false,
  enableAffluence: false,
  affluenceZones: [],
  affluenceResponse: [],
  affluenceTZ: 0,
};
