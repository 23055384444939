import {BookingState} from '../interfaces';

export const state: BookingState = {
  error: false,
  loading: false,
  bookingConfig: null,
  response: false,
  errorMessage: '',
  countBookings: [],
  currentBooking: null,
};
