













































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import Menu from '@/menu/components/Menu.vue';
import {Links} from '@/site/interfaces';

import AffluenceModal from '../components/AffluenceModal.vue';

@Component({
  components: {
    Menu,
    AffluenceModal,
  },
})
export default class Home extends Vue {
  @Getter('motd', {namespace: 'site'}) motd!: string | null;

  @Getter('name', {namespace: 'site'}) siteName!: string;

  @Getter('links', {namespace: 'site'}) links!: Links | null;

  @Getter('enableContact', {namespace: 'site'}) enableContact!: boolean;

  @Getter('hasArticles', {namespace: 'article'}) hasArticles!: boolean;

  @Getter('isBookingActivated', {namespace: 'site'}) isBookingActivated!: boolean;

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  @Getter('enableAffluence', {namespace: 'site'}) enableAffluence!: boolean;

  affluenceDialog = false;

  services: {
    icon: string;
    title: string;
    titleEn: string;
    show: boolean;
    name?: string;
    link?: string | null;
    click?: () => void;
  }[] = [];

  mounted(): void {
    this.setServices();
  }

  @Watch('$store.state.site.links')
  setLinks(): void {
    this.setServices();
  }

  @Watch('$store.state.article.articles')
  private showLocalService() {
    this.setServices();
  }

  setServices(): void {
    if (this.links && this.hasArticles) {
      this.services = [
        {
          icon: 'mdi-silverware',
          title: 'Menus',
          titleEn: 'Menus',
          name: 'menu',
          show: true,
          click: () => {
            this.selectService({name: 'menu', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-information-outline',
          title: 'Actualités',
          titleEn: 'News',
          name: 'articles',
          show: this.hasArticles,
          click: () => {
            this.selectService({name: 'articles', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-calendar-range',
          title: 'Réservation',
          titleEn: 'Booking',
          name: 'booking',
          show: this.isBookingActivated,
          click: () => {
            this.selectService({name: 'booking', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-account-circle',
          title: 'Compte',
          titleEn: 'Account',
          link: this.links.badgeUrl || null,
          show: !!this.links.badgeUrl,
        },
        {
          icon: 'mdi-room-service',
          title: 'Room Service',
          titleEn: 'Room Service',
          link: this.links.roomServiceUrl || null,
          show: !!this.links.roomServiceUrl,
        },
        {
          icon: 'mdi-basket',
          title: 'À emporter',
          titleEn: 'Takeaway',
          link: this.links.clickAndCollectUrl || null,
          show: !!this.links.clickAndCollectUrl,
        },
        {
          icon: 'mdi-square-edit-outline',
          title: 'Contact',
          titleEn: 'Contact',
          name: 'contact',
          show: this.enableContact,
          click: () => {
            this.selectService({name: 'contact', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-chart-line',
          title: 'Affluence',
          titleEn: 'Attendance',
          name: 'affluence',
          show: this.enableAffluence,
          click: () => {
            this.affluenceDialog = true;
          },
        },
      ].filter((service) => service.show);
    } else if (!this.links && this.hasArticles) {
      this.services = [
        {
          icon: 'mdi-silverware',
          title: 'Menus',
          titleEn: 'Menus',
          name: 'menu',
          show: true,
          click: () => {
            this.selectService({name: 'menu', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-information-outline',
          title: 'Actualités',
          titleEn: 'News',

          name: 'articles',
          show: this.hasArticles,
          click: () => {
            this.selectService({name: 'articles', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-calendar-range',
          title: 'Réservation',
          titleEn: 'Booking',

          name: 'booking',
          show: this.isBookingActivated,
          click: () => {
            this.selectService({name: 'booking', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-square-edit-outline',
          title: 'Contact',
          titleEn: 'Contact',

          name: 'contact',
          show: this.enableContact,
          click: () => {
            this.selectService({name: 'contact', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-chart-line',
          title: 'Affluence',
          titleEn: 'Attendance',

          show: this.enableAffluence,
          click: () => {
            this.affluenceDialog = true;
          },
        },
      ].filter((service) => service.show);
    } else if (this.links && !this.hasArticles) {
      this.services = [
        {
          icon: 'mdi-silverware',
          title: 'Menus',
          titleEn: 'Menus',

          name: 'menu',
          show: true,
          click: () => {
            this.selectService({name: 'menu', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-calendar-range',
          title: 'Réservation',
          name: 'booking',
          titleEn: 'Booking',

          show: this.isBookingActivated,
          click: () => {
            this.selectService({name: 'booking', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-account-circle',
          title: 'Compte',
          titleEn: 'Account',

          link: this.links.badgeUrl || null,
          show: !!this.links.badgeUrl,
        },
        {
          icon: 'mdi-room-service',
          title: 'Room Service',
          titleEn: 'Room Service',

          link: this.links.roomServiceUrl || null,
          show: !!this.links.roomServiceUrl,
        },
        {
          icon: 'mdi-basket',
          title: 'À emporter',
          titleEn: 'Takeaway',

          link: this.links.clickAndCollectUrl || null,
          show: !!this.links.clickAndCollectUrl,
        },
        {
          icon: 'mdi-square-edit-outline',
          title: 'Contact',
          titleEn: 'Contact',

          name: 'contact',
          show: this.enableContact,
          click: () => {
            this.selectService({name: 'contact', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-chart-line',
          title: 'Affluence',
          titleEn: 'Attendance',

          show: this.enableAffluence,
          click: () => {
            this.affluenceDialog = true;
          },
        },
      ].filter((service) => service.show);
    } else {
      this.services = [
        {
          icon: 'mdi-silverware',
          title: 'Menus',
          titleEn: 'Menus',

          name: 'menu',
          show: true,
          click: () => {
            this.selectService({name: 'menu', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-calendar-range',
          title: 'Réservation',
          name: 'booking',
          titleEn: 'Booking',

          show: this.isBookingActivated,
          click: () => {
            this.selectService({name: 'booking', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-square-edit-outline',
          title: 'Contact',
          titleEn: 'Contact',

          name: 'contact',
          show: this.enableContact,
          click: () => {
            this.selectService({name: 'contact', params: {id: this.$route.params.slug}});
          },
        },
        {
          icon: 'mdi-chart-line',
          title: 'Affluence',
          titleEn: 'Attendance',

          show: this.enableAffluence,
          click: () => {
            this.affluenceDialog = true;
          },
        },
      ].filter((service) => service.show);
    }
  }

  selectService(service: {name: string; params: {id: string}}): void {
    this.$router.push(service);
  }
}
