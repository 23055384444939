import {RouteConfig} from 'vue-router';

import Menu from './components/Menu.vue';

export const menuRoutes: Array<RouteConfig> = [
  {
    path: '/:slug/menus',
    name: 'menu',
    component: Menu,
    meta: {title: 'Menu'},
  },
];
