








import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import NoData from '@/core/components/NoData.vue';

import {Day} from '../interfaces';
import FilterDay from './FilterDay.vue';
import ListCards from './ListCards.vue';

@Component({
  components: {
    FilterDay,
    ListCards,
    NoData,
  },
})
export default class Menu extends Vue {
  @Getter('days', {namespace: 'menu'}) days!: Day[];

  @Getter('hasArticles', {namespace: 'article'}) hasArticles!: boolean;
}
