import spacetime from 'spacetime';

import {isBeforeDate, isToday, tzParis} from '@/core';

import {ClosedDate, CountBooking} from '../interfaces';

export function isClosedDay(
  workingDays?: {weekDay: number; enable: boolean}[]
): (date: string) => boolean {
  return (date) => {
    if (workingDays) {
      const workingDay = workingDays.find((wd) => wd.weekDay === spacetime(date).day());
      return workingDay ? !workingDay.enable : false;
    }
    return false;
  };
}

export function isClosedDate(closedDates: ClosedDate[]): (date: string) => boolean {
  return (date) =>
    closedDates.some((p) =>
      spacetime(date, tzParis).isBetween(
        spacetime(p.from, tzParis),
        spacetime(p.to, tzParis).add(1, 'day'),
        true
      )
    );
}

export function onBookableDays(n: string, bookableDays: number): boolean {
  return spacetime(n, tzParis).since(spacetime.now(tzParis)).diff.days < bookableDays;
}

export function isAvailableSlot(startTime: string, date: string): boolean {
  let startAt = spacetime(date, tzParis);
  startAt = startAt.hour(parseInt(startTime.split(':')[0], 10));
  startAt = startAt.minute(parseInt(startTime.split(':')[1], 10));
  if (isToday(startAt.format('iso') as string)) {
    return isBeforeDate(
      spacetime.now(tzParis).toLocalDate().toISOString(),
      startAt.format('iso') as string
    );
  }
  return true;
}

export function isAvailableSeats(
  startTime: string,
  slotSeats: number,
  countBookings: CountBooking[]
): boolean {
  if (countBookings.length > 0) {
    const foundDate = countBookings.find(
      (countBooking) =>
        spacetime(countBooking._id).goto(tzParis).format('{hour-24-pad}:{minute-pad}') === startTime
    );
    return foundDate ? slotSeats > foundDate.countSeats : true;
  }
  return true;
}

export function formatBookingDate(startTime: string, date: string): string {
  let startAt = spacetime(date);
  startAt = startAt.hour(parseInt(startTime.split(':')[0], 10));
  startAt = startAt.minute(parseInt(startTime.split(':')[1], 10));
  return startAt.format('iso') as string;
}
