import {MenuState} from '../interfaces';

export const state: MenuState = {
  days: [],
  currentMenu: null,
  loading: false,
  menus: [],
  selectedDay: null,
  selectedService: null,
  services: [],
  zones: [],
  error: false,
};
