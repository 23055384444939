import {Nutriscore, Picto} from '../interfaces';
import {PRODUCT_TYPE} from './enums';

export const ALLERGENES: Picto[] = [
  {
    name: 'Arachides',
    id: 'ARA',
    fileName: 'arachides.svg',
  },
  {
    name: 'Celeri',
    id: 'CEL',
    fileName: 'celeri.svg',
  },
  {
    name: 'Crustacés',
    id: 'CRU',
    fileName: 'crustaces.svg',
  },
  {
    name: 'Fruits à coque',
    id: 'FCQ',
    fileName: 'fruits-a-coque.svg',
  },
  {
    name: 'Gluten',
    id: 'GLU',
    fileName: 'gluten.svg',
  },
  {
    name: 'Lait',
    id: 'LAI',
    fileName: 'lait.svg',
  },
  {
    name: 'Lupin',
    id: 'LUP',
    fileName: 'lupin.svg',
  },
  {
    name: 'Mollusques',
    id: 'MOL',
    fileName: 'mollusques.svg',
  },
  {
    name: 'Moutarde',
    id: 'MOU',
    fileName: 'moutarde.svg',
  },
  {
    name: 'Oeuf',
    id: 'OEU',
    fileName: 'oeuf.svg',
  },
  {
    name: 'Poisson',
    id: 'POI',
    fileName: 'poisson.svg',
  },
  {
    name: 'Sésame',
    id: 'SES',
    fileName: 'sesame.svg',
  },
  {
    name: 'Soja',
    id: 'SOJ',
    fileName: 'soja.svg',
  },
  {
    name: 'Sulfite',
    id: 'SUL',
    fileName: 'sulfite.svg',
  },
];

export const ORIGINES: Picto[] = [
  {
    name: 'Agriculture biologique',
    id: 'BIO',
    fileName: 'ab.svg',
  },
  {
    name: 'Cuisine Vapeur',
    id: 'CUIVAP',
    fileName: 'cuisine_vapeur.png',
  },
  {
    name: 'Recette équilibrée',
    id: 'RECEQU',
    fileName: 'equilibre.png',
  },
  {
    name: 'Viande fraîche et française',
    id: 'VIFRFR',
    fileName: 'fraiche_et_francaise.png',
  },
  {
    name: 'Produit frais',
    id: 'PROFRA',
    fileName: 'frais.png',
  },
  {
    name: 'Légumes cuisinés par le chef',
    id: 'LECUCH',
    fileName: 'legumes_du_chef.png',
  },
  {
    name: 'Produit Local',
    id: 'PROLOC',
    fileName: 'local.png',
  },
  {
    name: 'Menu Label',
    id: 'MENLAB',
    fileName: 'menu_label.png',
  },
  {
    name: 'Menu Plaisir',
    id: 'MENPLA',
    fileName: 'menu_plaisir.png',
  },
  {
    name: 'Menu Malin',
    id: 'MENMAL',
    fileName: 'menu_malin.png',
  },
  {
    name: 'Pâtisserie réalisée par le chef',
    id: 'PATCHE',
    fileName: 'patisserie_du_chef.png',
  },
  {
    name: 'Plat du jour',
    id: 'PLAJOU',
    fileName: 'plat_du_jour.png',
  },
  {
    name: 'Pêche du jour',
    id: 'PECJOU',
    fileName: 'peche.png',
  },
  {
    name: 'Recette réalisée par le chef',
    id: 'RECCHE',
    fileName: 'recette_du_chef.png',
  },
  {
    name: 'Produit de saison',
    id: 'PROSAI',
    fileName: 'saison.png',
  },
  {
    name: 'Sans Gluten',
    id: 'SANGLU',
    fileName: 'sans_gluten.png',
  },
  {
    name: 'Sauce cuisinée par le chef',
    id: 'SAUCHE',
    fileName: 'sauce_du_chef.png',
  },
  {
    name: 'Cuisine spectacle (show cooking)',
    id: 'CUISPE',
    fileName: 'show_cooking.png',
  },
  {
    name: 'Recette végétarienne',
    id: 'RECVEG',
    fileName: 'vege.png',
  },
  {
    name: 'Viande bovine française',
    id: 'VIBOFR',
    fileName: 'vbf.png',
  },
  {
    name: 'Egalim',
    id: 'EGALIM',
    fileName: 'egalim.svg',
  },
  {
    name: 'Produit labellisé',
    id: 'PROLAB',
    fileName: 'produit_labellise.svg',
  },
  {
    name: 'Anti-gaspi',
    id: 'ANTGAS',
    fileName: 'anti_gaspi.svg',
  },
  {
    name: 'Viande EU',
    id: 'VIAEU',
    fileName: 'veu.svg',
  },
  {
    name: 'Viande française',
    id: 'VIAFRA',
    fileName: 'vf.svg',
  },
];

export const PRODUCT_TYPES: Picto[] = [
  {
    name: 'Entrée',
    id: PRODUCT_TYPE.ENT,
    fileName: 'entrees.png',
  },
  {
    name: 'Plat',
    id: PRODUCT_TYPE.PLA,
    fileName: 'plats.png',
  },
  {
    name: 'Dessert',
    id: PRODUCT_TYPE.DES,
    fileName: 'desserts.png',
  },
  {
    name: 'Fromage',
    id: PRODUCT_TYPE.FRO,
    fileName: 'fromages.png',
  },
  {
    name: 'Laitage',
    id: PRODUCT_TYPE.LAI,
    fileName: 'laitages.png',
  },
  {
    name: 'Boisson',
    id: PRODUCT_TYPE.BOI,
    fileName: 'boissons.png',
  },
  {
    name: 'Snacking',
    id: PRODUCT_TYPE.SNA,
    fileName: 'cafeteria.png',
  },
  {
    name: 'Formule',
    id: PRODUCT_TYPE.FOR,
    fileName: 'formule.png',
  },
  {
    name: 'Casiers connecté',
    id: PRODUCT_TYPE.LOC,
    fileName: 'casiers.png',
  },
];

export const NUTRISCORE: Nutriscore[] = [
  {
    id: 1,
    fileName: '1.svg',
    fileNameVertical: 'v_1.svg',
  },
  {
    id: 2,
    fileName: '2.svg',
    fileNameVertical: 'v_2.svg',
  },
  {
    id: 3,
    fileName: '3.svg',
    fileNameVertical: 'v_3.svg',
  },
  {
    id: 4,
    fileName: '4.svg',
    fileNameVertical: 'v_4.svg',
  },
  {
    id: 5,
    fileName: '5.svg',
    fileNameVertical: 'v_5.svg',
  },
];
