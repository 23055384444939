



































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import {formatDate, formatTime} from '@/core';

import {Booking} from '../interfaces';

@Component
export default class CancelBooking extends Vue {
  @Action('reset', {namespace: 'booking'}) reset!: () => void;

  @Action('getBooking', {namespace: 'booking'}) getBooking!: (id: string) => void;

  @Action('cancelBooking', {namespace: 'booking'}) cancelBooking!: (id: string) => void;

  @Getter('error', {namespace: 'booking'}) error!: boolean;

  @Getter('errorMessage', {namespace: 'booking'}) errorMessage!: string;

  @Getter('loading', {namespace: 'booking'}) loading!: boolean;

  @Getter('response', {namespace: 'booking'}) response!: boolean;

  @Getter('currentBooking', {namespace: 'booking'}) booking!: Booking | null;

  dialog = false;

  @Watch('$store.state.booking.error')
  bookingNotFound(): void {
    if (!this.booking) {
      this.$router.replace(`/${this.$route.params.slug}`);
      this.reset();
    }
  }

  getFormatDate = function getFormatDate(date: string): string {
    return formatDate(date);
  };

  getFormatTime = function getFormatDate(date: string): string {
    return formatTime(date);
  };

  mounted(): void {
    if (!this.booking) this.getBooking(this.$route.params.id);
  }

  onCancelBooking(): void {
    if (this.booking && this.booking._id) {
      this.cancelBooking(this.booking._id);
    }
  }

  goBooking(): void {
    this.$router.replace({name: 'booking', params: {id: this.$route.params.slug}});
  }
}
