import spacetime from 'spacetime';

import {AffluenceResponse} from '@/site/interfaces';

import {tzParis} from './date.tools';

export function setLabel(m: string, tz: number): string {
  let date = spacetime(m).goto(tz ? 'UTC' : tzParis);
  if (tz) {
    date = date.hour(date.hour() + tz);
  } else {
    const {isDST} = spacetime().timezone().current;
    date = date.hour(date.hour() + (isDST ? 0 : 1));
  }
  return `${date.format('{hour-24-pad}')}:${date.format('{minute-pad}')}`;
}

export function setLabels(affluenceResponse: AffluenceResponse[], tz: number): string[] {
  return affluenceResponse.map((response, index) =>
    !(index % 2) ? setLabel(response.start, tz) : ''
  );
}

export function isCurrentStep(start: Date, end: Date, tz: number): boolean {
  const {isDST} = spacetime().timezone().current;
  let sStart = spacetime(start).goto(tz ? 'UTC' : tzParis);
  let sEnd = spacetime(end).goto(tz ? 'UTC' : tzParis);
  let now = spacetime.now().goto(tz ? 'UTC' : tzParis);
  if (tz) {
    sStart = sStart.hour(sStart.hour() + tz);
    sEnd = sEnd.hour(sEnd.hour() + tz);
    now = now.hour(now.hour() + tz);
  } else {
    sStart = sStart.hour(sStart.hour() + (isDST ? 0 : 1));
    sEnd = sEnd.hour(sEnd.hour() + (isDST ? 0 : 1));
    now = now.hour(now.hour() + (isDST ? 0 : 1));
  }
  return now.isAfter(sStart) && now.isBefore(sEnd);
}

export function setPeople(affluenceResponse: AffluenceResponse[], tz: number): (number | null)[] {
  return affluenceResponse.map((response) =>
    isCurrentStep(new Date(response.start), new Date(response.end), tz) && response.people
      ? Math.round(response.people) || null
      : Math.round(response.avg) || null
  );
}

export function setPeopleIn(affluenceResponse: AffluenceResponse[], tz: number): (number | null)[] {
  return affluenceResponse.map((response) =>
    isCurrentStep(new Date(response.start), new Date(response.end), tz) && response.peopleIn
      ? Math.round(response.peopleIn) || null
      : Math.round(response.avgIn) || null
  );
}
