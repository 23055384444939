






























































































































import {debounce} from 'vue-debounce';
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import {isBeforeDate, isToday} from '@/core';

import {PRODUCT_TYPE, PRODUCT_TYPES} from '../constants';
import {Menu, Picto, Product, Zone} from '../interfaces';
import {filterTypesProduct} from '../tools';
import ModalFormule from './ModalFormule.vue';
import ModalProductDetail from './ModalProduct.vue';
import ProductCard from './ProductCard.vue';

@Component({
  components: {
    ModalProductDetail,
    ProductCard,
    ModalFormule,
  },
})
export default class ListCards extends Vue {
  @Getter('loadingMenu', {namespace: 'menu'}) loadingMenu!: boolean;

  @Getter('products', {namespace: 'menu'}) products!: Product[];

  @Getter('formulaZones', {namespace: 'menu'}) formulaZones!: Zone[];

  @Getter('formulaProducts', {namespace: 'menu'}) formulaProducts!: (id: string) => Product[];

  @Getter('error', {namespace: 'menu'}) error!: boolean;

  @Getter('isActivatedComment', {namespace: 'menu'}) isActivatedComment!: boolean;

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  @Getter('currentMenu', {namespace: 'menu'}) currentMenu!: Menu;

  productTypes: Picto[] = [];

  selectedProductTypeName = '';

  typeProduct = PRODUCT_TYPE;

  @Watch('$store.state.menu.currentMenu')
  private updateDay() {
    this.updateProductTypes();
  }

  mounted(): void {
    this.updateProductTypes();
  }

  created(): void {
    document.addEventListener('scroll', this.handleScroll);
  }

  destroyed(): void {
    document.removeEventListener('scroll', this.handleScroll);
  }

  set selectedProductType(value: string) {
    this.selectedProductTypeName = value;
  }

  get selectedProductType(): string {
    return this.selectedProductTypeName;
  }

  productsTypes(typeProduct: PRODUCT_TYPE | string): Product[] {
    return this.products.filter((product) => product.type === typeProduct);
  }

  hasProductsTypes(): boolean {
    return this.productTypes.some((productType) => this.productsTypes(productType.id).length);
  }

  hasProductsFormulas(): boolean {
    return this.formulaZones.some((formula) => this.formulaProducts(formula.code).length);
  }

  updateProductTypes(): void {
    this.productTypes = filterTypesProduct(this.products);
    if (this.formulaZones.length && this.hasProductsFormulas()) {
      this.productTypes.push(PRODUCT_TYPES[7]);
    }
  }

  scrollToProductType(productType: string): void {
    if (productType === 'Formule') {
      const element: HTMLElement = this.$refs[productType] as HTMLElement;
      window.scrollTo({left: 0, top: element.offsetTop, behavior: 'smooth'});
    } else {
      const element: HTMLElement[] = this.$refs[productType] as HTMLElement[];
      window.scrollTo({left: 0, top: element[0].offsetTop, behavior: 'smooth'});
    }
  }

  handleScroll(): void {
    debounce(() => {
      if (
        this.$refs[this.productTypes[0].name] &&
        (this.$refs[this.productTypes[0].name] as HTMLElement[])[0] &&
        window.scrollY >= (this.$refs[this.productTypes[0].name] as HTMLElement[])[0].offsetTop
      ) {
        const productTypes = this.productTypes.map((productType) => ({
          name: productType.name,
          position:
            productType.name !== 'Formule'
              ? (this.$refs[productType.name] as HTMLElement[])[0].offsetTop
              : (this.$refs[productType.name] as HTMLElement).offsetTop,
        }));
        const selectedProductType = productTypes.reduce((a, b) =>
          a.position <= window.scrollY && b.position > window.scrollY ? a : b
        );
        this.selectedProductTypeName = selectedProductType.name;
      } else {
        this.selectedProductTypeName = '';
      }
    }, '1000ms')();
  }

  openActivatedComment(): boolean {
    switch (this.customerId) {
      case 'Z16':
        if (isToday(this.currentMenu.date)) {
          const date = new Date();
          date.setHours(11, 30, 0, 0);
          return isBeforeDate(date.toISOString(), new Date().toISOString());
        }
        return false;
      default:
        return false;
    }
  }
}
